<template>
  <div>
    <title-header :title="title" :buttons="[]"></title-header>

    <v-form v-model="validData">
      <v-container class="ml-8">
        <v-col cols="12" md="5" sm="8">
          <v-text-field outlined label="*Nombre" v-model="user.displayName" :rules="rules.name"></v-text-field>

          <v-text-field
            outlined
            label="*Correo electrónico"
            :rules="rules.email"
            v-model="user.email"
          ></v-text-field>

          <v-switch v-model="changePassword" label="Cambiar contraseña"></v-switch>
          <v-text-field
            outlined
            label="*Nueva contraseña"
            v-model="newPassword"
            :type="showPassword ? 'text' : 'password'"
            :rules="rules.password"
            v-if="changePassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <p class="ml-2 mb-5 mt-5">*Campos obligatorios</p>
        </v-col>

        <v-divider width="80%"></v-divider>
        <p class="mt-5">{{blockActionText + ' cuenta'}}</p>
        <p max-width="2" style="width: 20rem">{{disableUserText}}</p>
        <v-btn
          class="mr-6 mb-5 mt-2"
          rounded
          outlined
          :color="user.isActive ? 'red' : 'primary'"
          width="10rem"
          @click="blockUserClicked()"
        >{{blockActionText}}</v-btn>

        <v-divider width="80%"></v-divider>
        <p class="mt-5">Eliminar cuenta</p>
        <p max-width="2" style="width: 20rem">{{deleteUserText}}</p>
        <v-btn
          class="mr-6 mb-5 mt-2"
          rounded
          outlined
          color="red"
          dark
          width="10rem"
          @click="removeUserClicked()"
        >Eliminar cuenta</v-btn>

        <v-divider width="80%"></v-divider>
        <div class="mt-5 mb-20">
          <v-btn class="mr-6" rounded outlined color="primary" width="10rem" dark @click="cancel()">Cancelar</v-btn>
          <v-btn

            rounded
            color="primary"
            width="10rem"
            @click="updateClicked()"
            :disabled="!validData"
          >Actualizar</v-btn>
        </div>
      </v-container>
    </v-form>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
      <v-card-title class="headline">Felicidades</v-card-title>
      <v-card-text>El usuario se ha actualizado satisfactoriamente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="cancel()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TitleHeader from '@/components/app/title-header/title-header'
import rules from '@/constants/input-validation'
import { USERS_SELECT_USER } from '@/store/mutations.type'
import {
  USERS_UPDATE_USER,
  USERS_BLOCK_USER,
  USERS_UNBLOCK_USER,
  USERS_REMOVE_USER
} from '@/store/actions.type'

export default {
  name: 'users-edit',
  props: {},
  data() {
    return {
      title: 'Editar usuario',
      showPassword: false,
      changePassword: false,
      showSnackbar: false,
      snackbarTimeout: 1000,
      newPassword: '',
      snackBarText: '',
      dialog: false,
      validData: false,
      disableUserText:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero',
      deleteUserText:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero',
      rules
    }
  },
  computed: {
    ...mapGetters({
      user: 'getSelectedUser'
    }),
    blockActionText() {
      return this.user.isActive ? 'Deshabilitar' : 'Habilitar'
    }
  },
  methods: {
    ...mapActions({
      updateUser: USERS_UPDATE_USER,
      blockUser: USERS_BLOCK_USER,
      unblockUser: USERS_UNBLOCK_USER,
      removeUser: USERS_REMOVE_USER
    }),
    ...mapMutations({
      selectUser: USERS_SELECT_USER
    }),
    cancel() {
      this.$router.push('/settings/users')
    },
    updateClicked() {
      if (this.newPassword !== '' && this.changePassword) {
        this.user.password = this.newPassword
      }
      this.updateUser(this.user)
        .then(response => {
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    },
    blockUserClicked() {
      if (this.user.isActive) {
        this.blockUser(this.user)
          .catch(() => {
            this.snackBarText = 'Se habilito el usuario'
            this.showSnackbar = true
          })
          .catch(e => {
            this.snackBarText = e.message
            this.showSnackbar = true
          })
      } else {
        this.unblockUser(this.user)
          .catch(() => {
            this.snackBarText = 'Se deshabilito el usuario'
            this.showSnackbar = true
          })
          .catch(e => {
            this.snackBarText = e.message
            this.showSnackbar = true
          })
      }
    },
    removeUserClicked() {
      this.removeUser(this.user)
        .then(response => {
          this.cancel()
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    }
  },
  created() {
    this.selectUser(this.$route.params.id)
  },
  components: {
    TitleHeader
  }
}
</script>

<style>
p {
  font-size: 13px;
}
</style>
